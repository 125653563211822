.popupChangeName {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.change {
    position: relative;
    padding: 32px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 32px;
    border-radius: 12px;
    width: 448px;
    align-items: center;
}

.popup__card-return {
    align-self: flex-start;
}

.change__close {
    position: absolute;
    top: 32px;
    right: 32px;
}

.input {
    width: 384px;
    background-color: var(--filter-card-color);
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.buttonNewCard {
    width: 188px;
    padding: 0;
}

.buttonPostSuccess {
    width: 384px;
}

.successFetch {
    width: 44px;
    height: 44px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .8;
    z-index: -1;
}

.steps__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-grey-color);
    text-align: center;
}

.step {
    text-align: center;
}

.approve__data {
    display: flex;
    align-items: center;
    padding: 8px;
    width: 100%;
    border: 1px solid var(--filter-color);
    border-radius: 12px;
}

.approve__title {
    padding: 12px;
}

.approve__cardImage {
    border-radius: 8px;
    width: 96px;
    height: 72px;
}

@media screen and (max-width: 768px) {
    .change {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .button {
        width: 160px;
    }
    .input {
        width: auto;
        min-width: 328px;
    }
    .buttons {
        gap: 4px;
    }
}