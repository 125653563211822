.catalog__folders {
    display: flex;
    gap: 12px;
    padding: 12px 12px 12px 16px;
    justify-content: space-between;
    border-bottom: 1px solid  var(--filter-color);
    list-style: none;
    position: relative;
}

.catalog__folders:last-child {
    border-bottom: none;
}

.folder-block {
    align-items: center;
    display: flex;
    gap: 12px;
}

.actions {
    width: 24px;
    height: 24px;
}

.catalog__color {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-right: 8px;
    border: 1px solid rgba(207, 216, 220, 1)
}