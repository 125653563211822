.popup {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}

.popup__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .8;
}

.popup__card-info {
    position: relative;
    display: flex;
}

.popup__close-overlay {
    cursor: pointer;
    position: absolute;
    top: 22px;
    right: 22px;
}

.popup__card-tools {
    background-color: white;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;  
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 464px;
    height: 684px;
}

.popup__card-author {
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 12px;
    color: var(--filter-card-text-color);
}

.popup__buttons {
    display: flex;
    gap: 8px;
    height: 48px;
}

.popup__button {
    width: 288px;
    height: 48px;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: var(--green-color);
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.popup__button:hover {
    background-color: var(--green-hover-color);
}

.popup__tool {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--filter-card-color);
}

.popup__tool:hover {
    background-color: var(--filter-color);
}

.popup__about-category {
    color: var(--filter-card-text-color);
    margin-bottom: 4px;
}

.popup__about {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.popup__tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.popup__map_container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.popup__text {
    font-family: "Inter";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--primary-color);
}
  
.popup__dot {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: var(--text-help-color);
}

.button__moderation {
    width: 196px;
}

.popup__copy {
    width: 350px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    color: var(--primary-color);
    padding: 12px 16px;
    border-radius: 8px;
    z-index: 5;
    box-shadow: 0px 55px 50px 20px rgba(38, 50, 56, 0.2);
    text-align: center;
    text-wrap: wrap;
}

.popup__copy-error-image {
    width: 200px;
}

@media screen and (max-width: 1440px) {
    .popup__card-tools {
        padding: 16px 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 360px;
        height: 580px;
    }
    .popup__close-overlay {
        top: 12px;
    }
    .popup {
        height: 100vh;
        width: 100vw;
    }
    .button__moderation {
        width: 146px;
    }
    .popup__button {
        width: 200px;
        padding: 10px;
    }
}

@media screen and (max-width: 1100px) {
    .popup__card-info {
        flex-direction: column;
    }
    .popup__close-overlay {
        top: 28px;
        right: 28px;
        z-index: 1;
    }
    .popup__card-tools {
        border-radius: 0;
        height: auto;
    }
}