@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --primary-color: #263238;
    --green-color: #4CAF50;
    --green-hover-color: #43A047;
    --secondary-color: #37474F;
    --grey-color: #B0BEC5;
    --filter-color: #ECEFF1;
    --filter-hover-color: #CFD8DC;
    --filter-card-color: #F2F4F5;
    --filter-card-text-color: #78909C;
    --border-color: #CFD8DC;
    --text-grey-color: #607D8B;
    --text-help-color: #90A4AE;
}

body {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
    font-family: "Inter";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--primary-color);
}

h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    color: var(--primary-color);
    padding: 32px 0;
}

h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    /* color: white; */
    color: var(--primary-color);
    text-align: center;
}

h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--primary-color);
}

h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-color);
}

h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-color);
}

h6 {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--primary-color);
}

button {
    border: none;
    outline: none;
    cursor: pointer;
    background: none;
}

span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: var(--text-help-color);
}

input {
    outline: none;
    border: none;
    width: 320px;
    height: 56px;
    padding: 16px;
    border-radius: 8px;
    position: relative;
    background-color: var(--secondary-color);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: var(--text-help-color);
}

a {
    text-decoration: none;
}