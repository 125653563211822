.footer {
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    position: fixed;
    bottom: 0;
    background-color: white;
    color: var(--primary-color);
    z-index: 3;
    padding: 10px 32px;
    box-shadow: 0px -55px 50px 0 rgba(38, 50, 56, 0.2);
}
.footer__frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
}
.add {
    position: absolute;
    top: -14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background-color: var(--green-color);
}
.add:hover {
    background-color: var(--green-hover-color);
}
.footer__text {
    margin-top: 24px;
}


@media screen and (max-width: 768px) {

}
