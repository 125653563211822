.preloader {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    flex-direction: column;
}

.preloader__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .2;
}

.preloader__spinner {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: -.125em;
    border: .25em solid green;
    border-right-color: transparent;
    border-radius: 50%;
    animation: rotate .75s linear infinite;
}

@keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }