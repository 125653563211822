.catalog__settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.add__folder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.catalog__info {
    border-radius: 12px;
    border: 1px solid  var(--filter-color);
}

.catalog__arrow_back {
    margin: 8px 16px 8px 8px;
    cursor: pointer;
}

.catalog__settings_title {
    display: flex;
    align-items: center;
}