.allFiltersPopup {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 4;
}

.allFilters__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .8;
    z-index: -1;
}

.allFilters__container {
    background-color: white;
    width: 381px;
    height: 100%;
    opacity: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 41px 24px 24px;
}

.allFiltersHeader {
    padding: 0 10px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 467px) {
    .allFilters__container {
        width: 100%;
    }
}

@media screen and (max-width: 381px) {
    .allFilters__container {
        width: 100%;
    }
}