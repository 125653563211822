.popup__return {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2; 
}

.popup__return-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .8;
    z-index: -1;
}

.return {
    display: flex;
    flex-direction: column;
    gap: 32px;
    border-radius: 12px;
    width: 384px;
    align-items: flex-start;
}

.return__block {
    width: 100%;
}

.return__textarea {
    width: 100%;
    border-radius: 8px;
    border: none;
    outline: none;
    background-color: var(--filter-card-color);
    padding: 8px;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

}

.return__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.return__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}