.createCardPopup {
  /* width: calc(100vw - 17px); */
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
}

.createCardPopup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.8;
}

.createCardPopup__container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: 16px;
  position: relative;
  width: 680px;
  padding: 32px;
  background-color: white;
  border-radius: 12px;
  overflow-y: auto;
  height: 90%;
}

.createCardPopup__inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.createCardPopup__inputs-containers {
  display: flex;
  justify-content: space-between;
}

.createCardPopup__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userAndDate {
  display: flex;
  align-items: center;
  gap: 4px;
}

.newCardClear {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
}

.newCardClear__input-box {
  padding: 0;
  width: 100%;
}

.newCardClear__input-box-text {
  height: 36px;
  justify-self: center;
  width: 130px;
}

.newCardClear__input {
  background-color: white;
  width: 450px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  border: 1px solid var(--grey-color);
  padding: 0 10px;
  height: 36px;
  color: var(--primary-color);
}

.newCardClear__input_wrapper {
  position: relative;
  width: 450px;
}

.newCardClear__input_block {
  display: flex;
  flex-direction: column;
}

.labels {
  width: 120px;
}

.newCardClear__input_error {
  color: red;
}

.photo {
  background-color: var(--filter-card-color);
  border: none;
  /* height: 40px; */
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.newCardButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rework {
  width: 300px;
}

@media screen and (max-width: 768px) {
  .slider {
    width: 701px;
    height: 560px;
    border-radius: 0;
  }
  .createCardPopup__container {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .createCardPopup__title {
    padding: 16px 0 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.addArticle {
  position: absolute;
  top: 3px;
  right: 4px;
  background-color: white;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: black;
  border: none;
  cursor: pointer;
  font-size: large;
}

.listArticles {
  display: flex;
  flex-direction: row;
  gap: 2px;
  margin-top: 5px;
  flex-wrap: wrap;
  max-width: 450px;
}

.artcl {
  display: flex;
  padding: 0 5px;
  flex-direction: row;
  background: none;
  font-weight: 400;
  border: 1px solid #c7c7d1;
  border-radius: 6px;

  > span {
    font-size: 16px;
    line-height: 24px;
    color: #263238;
  }

  > button {
    cursor: pointer;
    color: #90a4ae;
    width: 22px;
    height: 22px;
    display: inline-block;
    text-align: center;
    margin: 0 -5px 0 0px;
    border-radius: 0 3px 3px 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }

  > button:hover {
    color: tomato;
    opacity: 0.9;
  }
}
