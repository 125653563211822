.notFound {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    flex-direction: column;
    position: relative;
}

.notFound__h1 {
    margin: 32px 0 16px;
    color: white;
    padding: 0;
}

.notFound__span {
    max-width: 448px;
    text-align: center;
}

.notFound__link {
    width: 120px;
    height: 40px;
    border-radius: 8px;
    background-color: var(--green-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    /* margin-bottom: 48px; */
}

.notFound__link-text {
    color: white;
    
}

.text-not-found {
    position: absolute;
    bottom: 0;
}