.filter {
    padding: 4px 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    color: var(--primary-color);
    background-color: var(--filter-color);
    gap: 8px;
    padding-right: 4px;
}

.filter_clear:hover {
    background-color: var(--filter-hover-color);
}

.filter_card {
    color: var(--filter-card-text-color);
    background-color: var(--filter-card-color);
    padding-right: 12px;
}

.filter_clear {
    padding-right: 12px;
}

.filter_Btn {
    width: 20px;
    height: 20px;
}