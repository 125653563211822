.select {
    border-radius: 8px !important;
    outline: none;
    width: 216px !important;
    height: 40px !important; 
    box-shadow: none !important;
    padding: 0 10px 0 16px !important;
}


.select__element:hover {
    background-color: var(--filter-hover-color);
}

.react-dropdown-select-dropdown {
    top: 45px !important;
    border-radius: 12px !important;
    padding: 8px !important;
}

.react-dropdown-select-content {
    width: 216px !important;
    align-items: center;
}

.react-dropdown-select-input {
    height: 100% !important;
    font-family: "Inter" !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
    color: var(--primary-color) !important;
}

.css-z2hyoe-ItemComponent.react-dropdown-select-item-selected {
    background: white !important;
}

.custom-checkbox {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.element {
    color: var(--primary-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 2px;
}

.custom-checkbox+.element__text {
    display: inline-flex;
    align-items: center;
    user-select: none;
}
  .custom-checkbox+.element__text::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
}
.custom-checkbox:checked+.element__text::before {
    border-color: var(--green-hover-color);
    background-color: var(--green-color);
    background-image: url(../../../images/dropdown/checkbox_selected.svg);
}
@media screen and (max-width: 768px) {
    .select {
        flex-direction: row-reverse !important;
        width: 164px !important;
        padding: 10px 0 10px 0 !important;
        border: none !important;
    }
    .react-dropdown-select-content {
        margin-left: 8px !important;
    }
}