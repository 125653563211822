.info {
    border-radius: 12px;
    border: 1px solid  var(--filter-color);
    padding: 0 12px;
}

.block {
    width: 100%;
    display: flex;
    gap: 12px;
    padding: 12px 0;
    justify-content: space-between;
    align-items: center;
}

.block__text {
    display: flex;

}

.line {
    border-bottom: 1px solid var(--filter-color);
}

.text {
    width: 444px;
    margin-left: 12px;
    text-align: left;
}

.chevron {
    display: flex;
    align-items: center;
    justify-content: center;
}
