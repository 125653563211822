.main {
    padding-top: 72px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .main {
        padding-top: 105px;
    }
}