.account__bg{
    border-radius: 20px 20px 0px 0px;
    background-color: #fff;
    display: flex;
    padding: 32px 32px 20px 32px;
    gap: 32px;
    align-items: center;
    position: relative;
}

.account {
    padding-top: 72px;
}

.account__info {
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: space-between;
    height: calc(100vh - 178px);
    top: 104px;
}

.account__block {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.account__photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 88px;
    border-radius: 44px;
    background-color: var(--filter-color);
    color: var(--filter-card-text-color);
    object-fit: cover;
}

.account__name {
    margin-top: 16px;
    margin-bottom: 4px;
}

.account__head {
    padding-left: 12px;
}

.account__photos-textbox {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
}

.account__photos-box {
    cursor: pointer;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    display: flex;
    align-items: center;
    width: 260px;
    border-radius: 8px;
    background-color: white;
}
.account__photos-box:hover {
    background-color: var(--filter-card-color);
}

.account__photos-box-active {
    background-color: var(--filter-card-color);
}

.account__image-action {
    margin-right: 8px;
}

.account__cards {
    margin-left: 270px;
    width: 100%;
}

.count__on-action-cards {
    background-color: rgba(244, 67, 54, 1);
    width: 16px;
    height: 16px;
    border-radius: 8px;
    display: block;
    font-size: 10px !important;
    font-weight: 600;
    line-height: 16px !important;
    text-align: center;
    color: white;
    margin-left: 8px;
}

@media screen and (max-width: 990px) {
    .account__cards {
        display: flex;
        margin-left: 0;
        justify-content: center;
    }
    .account__bg {
        padding-top: 60px;
    }
    .catalog__settings-title {
        font-size: 24px;
        line-height: 28px;
    }
}