.auth {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    padding: 64px;
    background-color: rgba(55, 71, 79, 0.2);
    border-radius: 20px;
}

.inputs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    width: 320px;   
}

.auth__btn {
    background-color: var(--green-color);
    outline: none;
    border: none;
    border-radius: 8px;
    width: 320px;   
    height: 48px;
    cursor: pointer;
}

.auth__btn:hover {
    background-color: var(--green-hover-color);
}

.auth__btn-text {
    color: white;
}

.input__auth {
    position: relative;
}

.placeholder {
    position: absolute;
    top: 16px;
    left: 16px;
}

.auth__text {
    width: 320px;
    text-align: center;
    color: white;
}
.forgot {
    color: var(--text-help-color) !important;
}
.auth__change {
    width: 44px;
    height: 44px;
}
.error {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: red !important;
}
.input__unauthorized {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: 1px solid red !important;
}
.auth__error {
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: flex-start;
}

@media screen and (max-width: 768px) {
    .inputs {
        width: 296px;   
    }
    input {
        width: 296px;
    }
    .auth__btn {
        width: 296px;   
    }
    .container {
        padding: 32px;
    }
  }