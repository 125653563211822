.button {
    height: 40px;
    padding: 10px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.primary {
    background-color: var(--green-color);
    color: white;
}

.secondary {
    background-color: var(--filter-card-color);
    color: var(--primary-color);
}

.primary:hover {
    background-color: var(--green-hover-color);
}

.secondary:hover {
    background-color: var(--filter-color);
}

.preloader__spinner-button {
    display: flex;
    align-items: center;
    gap: 12px;
}