.header {
    width: 100vw;
    display: flex;
    justify-content: center;
    position: fixed;
    background-color: var(--primary-color);
    color: white;
    z-index: 3;
    padding: 16px 0;
    border-bottom: 1px solid var(--secondary-color);
}

.header__container {
    max-width: 1376px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
}

.header__search {
    height: 40px;
    width: 912px;
    min-width: 210px;
    background-color: var(--secondary-color);
    padding: 10px 16px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
}

.header__searchInput {
    color: var(--grey-color);
    background-color: var(--secondary-color);
    border: none;
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    flex: 1;
    padding: 0;
    height: 100%;
    min-width: 160px;
}

.header__notificationContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.header__profile {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: var(--secondary-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
    position: relative;
}

.productsOnAction {
    border: 2px solid red;
}

.header__countOnAction {
    background-color: rgba(244, 67, 54, 1);
    width: 16px;
    height: 16px;
    border-radius: 8px;
    display: block;
    font-size: 10px !important;
    font-weight: 600;
    line-height: 16px !important;
    text-align: center;
    color: white;
    margin-left: 8px;
    position: absolute;
    top: -4px;
    right: -4px;
}

.header__mobile {
    display: flex;
    gap: 8px;
}
.header__profileName, .header__profile-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: white;
}
.header__filters {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
}
.button:hover {
    background-color: var(--green-hover-color);
}
.buttonHeader {
    margin-right: 7px;
}
@media screen and (max-width: 1439px) {
    .header__container {
        width: 1016px;
    }
}
@media screen and (max-width: 1139px) {
    .header__container {
        width: 872px;
    }
}



@media screen and (max-width: 939px) { 
    .header__container {
        width: 672px;
    }
    .header__search {
        width: 400px;
    }
}
    
    
@media screen and (max-width: 768px) {
    .header {
        padding-top: 10px;
        padding-bottom: 12px;
        border: none;
    }
    .header__container {
        gap: 12px;
        flex-direction: column;
        width: 1016px;
    }
    .header__logo {
        width: 128px;
        height: 32px;
    }
    .header__search {
        position: relative;
        width: 220px;
    }
    .header__close_icon {
        position: absolute;
        right: 4px;
        bottom: 8px;
    }
}
