.toggle-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
  
.toggle-label {
    display: block;
    width: 36px;
    height: 20px;
    border-radius: 10px;
    background-color: var(--border-color);
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s, box-shadow 0.3s;
}
  
.toggle-label::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: white;
    transition: transform 0.3s ease;
}

.all-text {
    font-weight: 500;
    font-size: 16px;
}

.all-filters {
    padding: 0 10px 0 16px;
}
  
#toggle-btn {
    display: none;
}
  
#toggle-btn:checked + .toggle-label::before {
    transform: translateX(16px);
}

#toggle-btn:checked + .toggle-label {
    background-color: var(--green-color);
}  
.toggle-label:hover {
    background-color: var(--filter-card-text-color);
}