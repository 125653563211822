.my_products {
    display: grid;
    grid-template-columns: repeat(4, 332px);
    justify-content: space-between;
    column-gap: 16px;
}
    
@media screen and (max-width: 1740px) {
    .my_products {
        width: 1090px;
        grid-template-columns: repeat(3, 332px);
        row-gap: 16px;
    }
}

@media screen and (max-width: 1439px) {
    .my_products {
        width: 800px;
        grid-template-columns: repeat(2, 332px);
    }
}

@media screen and (max-width: 1139px) {
    .my_products {
        width: 600px;
        grid-template-columns: repeat(2, 280px);
    }
}

@media screen and (max-width: 989px) {
    .my_products {
        width: 900px;
        grid-template-columns: repeat(3, 280px);
    }
}

@media screen and (max-width: 939px) {
    .my_products {
        justify-content: center;
        width: 700px;
        grid-template-columns: repeat(2, 328px);
    }
}

@media screen and (max-width: 767px) {
    .my_products {

        gap: 16px;
        grid-template-columns: repeat(1, 328px);
    }
}