.dropdown {
    background-color: var(--secondary-color) !important;
    border-radius: 8px;
}

.allFiltersDropdown__dropdown {
    background-color: white !important;
    border-radius: 8px;
}

.dropdown-select {
    padding: 0 10px 0 16px !important;
    outline: none !important;
    box-shadow: none !important;
    height: 40px !important;
    border: none !important;
    width: 216px !important;
}

.allFiltersDropdown {
    padding: 0 10px 0 16px !important;
    outline: none !important;
    box-shadow: none !important;
    height: 40px !important;
    border: none !important;
    width: 316px !important;
}

.dropdown-main {
    position: relative;
}

.dropdown__content {
    position: absolute;
    top: 45px;
    left: -20px;
    background-color: white;
    border-radius: 12px;
    width: 100%;
    color: var(--primary-color) !important;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 260px;
    z-index: 3;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}

.dropdown__item {
    display: flex;
    gap: 8px;
}

.dropdown__item_select_icon {
    cursor: pointer;
}

.dropdown-btn {
    color: white;
}

.dropdown__arrow {
    display: flex;
    align-items: center;
}

.countFilters {
    background-color: rgba(244, 67, 54, 1);
    width: 16px;
    height: 16px;
    border-radius: 8px;
    display: block;
    font-size: 10px !important;
    font-weight: 600;
    line-height: 16px !important;
    text-align: center;
    color: white;
}

.placeholderFilters {
    display: flex;
    align-items: center;
    gap: 8px;
}

.dropdown {
    height: 40px;
}

.color {
    display: flex;
    align-items: center;
    margin-left: 30px;
}

.element__color {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-right: 8px;
    border: 1px solid rgba(207, 216, 220, 1)
}

@media screen and (max-width: 1439px) {
    .dropdown-select {
        padding: 0 10px 0 16px !important;
        width: 192px !important;
    }
}

@media screen and (max-width: 1139px) {
    .dropdown-select {
        width: 206px !important;
    }
}

@media screen and (max-width: 381px) {
    .allFiltersDropdown {
        width: 286px !important;
    }
}