.slider {
    width: 912px;
    height: 684px;
    position: relative;
    background-color: white;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}

.image {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider__button {
    position: absolute;
    top: calc(50% - 24px);
    height: 48px;
    width: 48px !important;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px 8px 16px 0px #00000014;
}

.slider__button_right {
    right: 16px;
}

.slider__button_left {
    left: 16px;
}

@media screen and (max-width: 1440px) {
    .slider {
        width: 701px;
        height: 580px;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
    }
}

@media screen and (max-width: 1100px) {
    .slider {
        width: 360px;
        height: 270px;
    }
    .image {
        border-radius: 0;
    }
}