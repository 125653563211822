.catalog {
    /*border-radius: 20px 20px 0px 0px;*/
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 32px 32px 20px 32px;
    gap: 32px;
    align-items: center;
    position: relative;
}

.catalog__filters {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    width: 1376px;
}

.catalog__tools {
    display: flex;
    justify-content: space-between;
    width: 1376px;
}

.catalog__tool {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.catalog__tool-btn {
    display: flex;
    border: none;
    outline: none;
    background: none;
    align-items: center;
    gap: 8px;
    padding: 10px 0;
}

.catalog__tool-img {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.catalog__counter {
    color: var(--text-grey-color);
    width: 190px;
    text-wrap: wrap;
    text-align: right;
}

.catalog__counter-none {
    text-align: center;
}

.cards {
    /* width: 1360px; */
    display: grid;
    grid-template-columns: repeat(4, 332px);
    row-gap: 32px;
    justify-content: space-between;
    column-gap: 16px;
}
.view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

@media screen and (max-width: 1439px) {
    .cards {
        grid-template-columns: repeat(3, 328px);
        row-gap: 16px;
    }
    .catalog__filters {
        width: 1016px;
    }
    
    .catalog__tools {
        width: 1016px;
    }
}

@media screen and (max-width: 1139px) {
    .cards {
        grid-template-columns: repeat(3, 280px);
    }
    .catalog__tools {
        width: 872px;
    }
    .catalog__filters {
        width: 872px;
    }
}

@media screen and (max-width: 939px) {
    .cards {
        grid-template-columns: repeat(2, 328px);
    }
    .catalog__tools {
        width: 672px;
    }
    .catalog__filters {
        width: 672px;
    }
}

@media screen and (max-width: 768px) {
    .cards {
        width: 328px;
        grid-template-columns: repeat(1, 328px);
        gap: 16px;
    }
    .grid {
        grid-template-columns: repeat(2, 156px);
    }
    .catalog {
        padding: 16px;
    }
    .catalog__tool {
        gap: 8px;
    }
    .catalog__tools {
        width: auto;  gap: 8px;
    }
}