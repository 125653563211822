.card {
  width: 332px;
  box-sizing: content-box;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  position: relative;
}

.card__name-author {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.card_selected {
  border: 1px solid var(--green-color);
}

.card:hover {
  border: 1px solid var(--filter-color);
}

.card__hover {
  position: absolute;
  width: 332px;
  height: 246px;
  border-radius: 8px;
  opacity: 0;
  z-index: 1;
}

.card:hover .card__hover {
  background-color: black;
  opacity: 0.1;
}

.card:hover .card__image-actions {
  display: flex;
}

.card__img {
  width: 332px;
  height: 246px;
  border-radius: 8px;
  overflow: hidden;
  object-fit: cover;
}

.card__info {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 280px;
}

.card__author {
  margin-top: 4px;
  font-size: 14px;
  line-height: 12px;
  color: #90a4ae;
}

.card__filters {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.card__image-actions {
  position: absolute;
  display: none;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  top: 197px;
  right: 12px;
  z-index: 2;
}

.card__imageAction {
  height: 40px;
  box-shadow: 0px 8px 16px 0px #00000014;
  border-radius: 20px;
}

@media screen and (max-width: 1139px) {
  .card {
    width: 280px;
    border-radius: 12px;
  }
  .card__hover {
    height: 210px;
    width: 280px;
  }
  .card__img {
    width: 280px;
    height: 210px;
  }
  .card__image-actions {
    top: 157px;
  }
}

@media screen and (max-width: 939px) {
  .card__hover {
    width: 328px;
    height: 249px;
  }
  .card {
    width: 328px;
  }
  .card__img {
    width: 328px;
    height: 249px;
    border-radius: 8px;
  }
  .card__img_two {
    width: 156px;
    height: 116px;
  }
  .card__hover_two {
    width: 156px;
    height: 180px;
  }
  .card_two {
    width: 156px;
  }
  .card__info {
    min-width: 156px;
  }
  .card__image-actions {
    top: 197px;
  }
}

.card__copy {
  max-width: 280px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: var(--primary-color);
  padding: 12px 16px;
  border-radius: 8px;
  z-index: 5;
  text-wrap: nowrap;
  box-shadow: 0px 55px 50px 20px rgba(38, 50, 56, 0.2);
}

.card__copy-error-image {
  width: 120px;
}

.card__copy-error {
  top: 36%;
  width: 260px;
  flex-direction: column;
  text-wrap: wrap;
  text-align: center;
}

.article {
  font-size: 14px;
  color: var(--filter-card-text-color);
}
