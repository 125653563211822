.actions__buttons {
    position: absolute;
    top: 50px;
    right: 0;
    padding: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 260px;
    border-radius: 12px;
    box-shadow: 0px 0px 32px 0px rgba(38, 50, 56, 0.08);
    z-index: 2;
}

.actions__buttons_popup {
    top: 150px;
    right: 24px;
}

.action__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}

.action__button-text {
    color: rgba(244, 67, 54, 1);
}

@media screen and (max-width: 768px) {
    .actions__buttons {
        width: 100%;
    }
}