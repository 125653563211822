.createCardInput-select {
    padding: 0 8px 0 0!important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    background-color: var(--filter-card-color);
    width: 450px !important;
}

.newCardClear__input-box {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
}

.react-dropdown-select-option {
    background: none !important;
    border: 1px solid #C7C7D1;
    border-radius: 6px !important;
}

.react-dropdown-select-option-label {
    color: var(--primary-color) !important;
}

.react-dropdown-select-type-single span {
    color: var(--primary-color) !important;
    padding-left: 5px !important;
}

.newCardClear__input-photo {
    position: relative;
    text-align: center;
    background-color: var(--filter-card-color);
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    color: var(--primary-color);
}

.newCards__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    padding: 8px;
}
    
.newCardClear__photo {
    background: none;
    position: absolute;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.filesError {
    border: 1px solid red;
}

.newCardClear__input-text {
    margin-top: 24px;
}

.label__photo {
    margin-top: 24px;
    display: block;
    width: 139px;
    z-index: 1;
}

.newCardClear__selected {
    overflow: hidden;
    object-fit: cover;
    width: 139px;
    border-radius: 4px;
    /* max-width: 616px;
    max-height: 462px; */
}

.newCardClear__added-files {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.newCardClear__delete-file {
    position: absolute;
    bottom: 36px;
    right: 36px;
}

.loadFileContainer {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.returnText__container {
    background-color: #FFEBEE;
    border-radius:  12px;
    color: #F44336;
    padding: 16px;
}